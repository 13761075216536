import Slider from "react-slick";
// import store from "../../store";
import React, { useEffect, useRef, useState } from "react";
import './topbanner.css'
import { Button } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { format } from "date-fns";

const TopBanner = (props) => {

    const navigate = useNavigate()

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 2000,
        cssEase: "linear",
        adaptiveHeight: true,

    };

    return (
        <>
            <div className="sliderTopBanner">

                {/* ------- image slider --------- */}
                <Slider {...settings}>

                   
                   
                    {props.slidedata.map((x) => {

                        return (
                            <div key={x._id} > 
                                <div className="tobanner_wrapper">
                                    <div className="tobanner_subwrap">


                                        <div className="content">
                                            <div className="heading">
                                                <h1 className="projectName"> {x.project_name}</h1>
                                                <p className="directedby"> A Film By {x.directors_name}</p>
                                            </div>

                                            <div className="desciption">
                                               <div dangerouslySetInnerHTML={{__html: x.description}} className="desc"/>
                                            </div>

                                            <div className="casting">
                                                <ul>
                                                    {x.actors.map((x) => <li className="actorName"> {x.name}</li>)}
                                                </ul>

                                                <h3 className="date"> RELEASING ON  {format(x.release_date, 'dd MMM') } </h3>
                                            </div>
                                        </div>

                                        <div className="img_Section">
                                                <div className="content">
                                                <img src={x.poster_horizontal} className="img" alt="" onClick={() => window.open(x.trailer_link, '_blank')} />
                                                    <p className="trailer-link"> WATCH TRAILER ON &nbsp; <YouTubeIcon className="ytIcon" onClick={()=> window.open(x.trailer_link, '_blank')}/> </p>
                                                 </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        );
  
                })}
                  








                </Slider >

                {/* ----------------- */}



            </div >
        </>
    )
}

export default TopBanner;
