import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import '../ArtistDirectory /artistdirct.css';
import { addTeamMember, findTeamMember, resetEditDataMember, resetTeamFromSubmissionState, updateTeamMember } from './TeamReducer';
import BackendTopBar from '../../../Layout/BackendHeader/BackendTopBar';
import { Button, FormControl, FormHelperText, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import ImageUploader from '../../../CommonComponent /ImageUpload/ImageUploader';
import { useNavigate, useParams } from 'react-router-dom';
import store from '../../../store';

const TeamAddEdit = () => {
    const param = useParams();
    const [shrink, setshrink] = useState(false);
    const fromSubmissionState = useSelector((state) => state?.teamSlice?.fromSubmissionState);
    const [image, setImage] = useState([]);
    const loadingMember = useSelector((state) => state?.teamSlice?.loading);
    const [data, setData] = useState();
    const accessToken = useSelector((state) => state?.loginSlice?.access_token ? state.loginSlice.access_token : null);
    const editData = useSelector((state) => state?.teamSlice?.memberEditData ? state.teamSlice.memberEditData : null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { control, watch, setValue, getValues, handleSubmit, formState: { errors }, clearErrors } = useForm();

    const onChange = (event, flag) => {
        console.log('event.target', event, flag)
        setValue(flag, event.target.value)
        clearErrors(flag)
    }

    const onSubmit = () => {
        if (image.length === 1) data.image = image[0];
        console.log('data :>> ', data);
        // if (data.accessToken)
        if(param.id){
            data._id = param.id;
            dispatch(updateTeamMember(data));
        }else{
        data.name = data.name.trim();
        dispatch(addTeamMember(data));
        }
    }

    const memberDesignation = [
        { "key": "director", "val": "Director" },
        { "key": "script_writer", "val": "Script Writer" },
        { "key": "pr", "val": "PR and Media Relations" },
        { "key": "branding", "val": "Branding" },
        { "key": "film_strategist", "val": "Film Strategist" },
        { "key": "social_Media_specislist", "val": "Social Media Specislist" },
        { "key": "graphics_vfx", "val": "Graphics and VFX" },
        { "key": "executive_producer", "val": "Executive Producer" },
        { "key": "look_specialist", "val": "Look Specialist" },
        { "key": "costume_designer", "val": "Costume Designer" },
        { "key": "production_designer", "val": "Production Designer" },
        { "key": "production_controller", "val": "Production Controller" },
        { "key": "dop", "val": "Director Of Photography" },
        { "key": "asstt_director", "val": "Asstt Director" },

    ];


  

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log("value==========================>", value, "name--------->", name, "type---------->", type)
            setData({ ...value, accessToken: accessToken })
        }
        );
        return () => subscription.unsubscribe();
    }, [watch]);


    useEffect(() => {
        if(editData){
            setValue('name',editData.name);
            setValue('designation',editData.designation);
            setImage([editData.image]);
            setshrink(true);
        }
    }, [editData]);

    useEffect(() => {
        if(!editData && param.id)
        dispatch(findTeamMember(`_id=${param.id}`));

        return ()=>store.dispatch(resetEditDataMember());

    }, [])

    useEffect(() => {
        console.log('fromSubmissionState', fromSubmissionState);
        if (fromSubmissionState)
            navigate("/dashboard", { replace: true });
        return () => store.dispatch(resetTeamFromSubmissionState({ fromSubmissionState: false }));

    }, [fromSubmissionState]);
    

  return (
    <>


    <div className="main-block">

        <div className="artist_main_wrapper">

            <BackendTopBar />

        </div>

        <div className='form_section'>
            <form>


                <div className='firstSection'>

                    <div className='first_block blocks'>

                        <h3> Member Name </h3>
                        <div className=''>

                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "This field is required" }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <TextField id="outlined-basic" InputLabelProps={{ shrink: shrink}} label="Member Name" variant="outlined" className='form_feilds' value={value?value:null} onBlur={onBlur} onChange={onChange} 
                                    error={Object.keys(errors).length > 0 && errors.name != null}
                                        helperText={errors.name && "This Field is Required"}
                                    />
                                )}
                            />



                        </div>
                    </div>


                    <div className='second_block blocks'>

                        <h3> Designation </h3>
                        <div>
                            <Controller
                                name="designation"
                                control={control}
                                rules={{ required: "This field is required" }}
                                // defaultValue=[]
                                render={({ field: { onChange, onBlur, value } }) => (

                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <InputLabel shrink id="demo-multiple-chip-label">Designation</InputLabel>


                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="drive-type-select"
                                            value={value?value:null} 
                                            onBlur={onBlur}
                                            onChange={onChange}

                                            input={<OutlinedInput label="Name" />}
                                            error={Boolean(errors.designation)}
                                        // MenuProps={MenuProps}
                                        >
                                            {memberDesignation?.map((name) => (
                                                <MenuItem
                                                    key={name.key}
                                                    value={name.key}
                                                >
                                                    {name.val}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error={Object.keys(errors).length > 0 && errors.designation != null && errors.designation}>{errors.designation && "This Field is Required"}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>

                    </div>
                </div>

                <div className='secondSection'>

                    <div className='first_block blocks'>
                        <h3> Image </h3>
                        <ImageUploader urls={image} setUrls={setImage} />
                    </div>
                </div>
                                    {loadingMember && <LinearProgress color="success" />}


                <div className='buttonSection'>
                    <Button variant="contained" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </div>
            </form>


        </div>

    </div>


</>
  )
}

export default TeamAddEdit