import './frontendheader.css'
import logo from '../../img/logo.png'
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

const FrontendHeader = () => {
    
    const [menuToogle, setMenuToogle] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <div className='mainMenuWrapper-frontend'>
                <div className="headerMenu">
                    <ul className="menuLeft">
                        <li className="logo">
                            <img src={logo} className='logo_name' alt='Logo' onClick={() => navigate('/')} />
                        </li>
                    </ul>

                    <ul className='mobMenu'>
                        <li className='icon'>
                            <MenuIcon className={menuToogle===false? "displayBlock" : "displayNone" } onClick={()=>setMenuToogle(true)} />

                            <CloseIcon className={menuToogle? "displayBlock": "displayNone" } onClick={()=>setMenuToogle(false)} /> 
                        </li>
                    </ul>

                    <ul className={menuToogle===false? "menu" : "menuRight"}>
                        <li className="tabs" onClick={() => { navigate('/'); setMenuToogle(false) }}>  HOME </li>
                        <li className="tabs" onClick={() => { navigate('/projects'); setMenuToogle(false); }}> PROJECTS </li>
                        <li className="tabs" onClick={() => { navigate('/about'); setMenuToogle(false); }}> ABOUT US </li>
                        <li className="tabs" onClick={() => { navigate('/contact'); setMenuToogle(false); }}> CONTACTS</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default FrontendHeader