import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import BackendTopBar from "../../../Layout/BackendHeader/BackendTopBar";
import './artistdirct.css'
import '../Dashboard/dashboard.css'
import { Box, Button, Chip, CircularProgress, FormControl, FormHelperText, InputLabel, LinearProgress, MenuItem, OutlinedInput, Pagination, Select, TextField } from "@mui/material";
import ImageUploader from "../../../CommonComponent /ImageUpload/ImageUploader";
import { useDispatch, useSelector } from 'react-redux';
import { addArtist, findArtist, resetArtistFromSubmissionState, resetEditDataArtist, updateArtist } from './ArtistReducer';
import { useNavigate, useParams } from 'react-router-dom';
import store from "../../../store";

const ArtistAddEdit = () => {
    const param = useParams();
    const [shrink, setshrink] = useState(false);
    const [image, setImage] = useState([]);
    const loading = useSelector((state) => state?.artistSlice?.loading);
    const [data, setData] = useState();
    const accessToken = useSelector((state) => state?.loginSlice?.access_token ? state.loginSlice.access_token : null);
    const editData = useSelector((state) => state?.artistSlice?.artistEditData ? state.artistSlice.artistEditData : null);
    const fromSubmissionState = useSelector((state) => state?.artistSlice?.fromSubmissionState ? state.artistSlice.fromSubmissionState : false);
    const { control, watch, setValue, getValues, handleSubmit, formState: { errors }, clearErrors, setFocus } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();





    const onChange = (event, flag) => {
        console.log('event.target', event, flag)
        setValue(flag, event.target.value)
        clearErrors(flag)
    }

    const onSubmit = () => {
        if (image.length === 1) data.image = image[0];
        console.log('data :>> ', data);
        // if (data.accessToken)
        if(param.id){
            data._id = param.id;
            dispatch(updateArtist(data));
        }else{
        data.name = data.name.trim();
        dispatch(addArtist(data));
        }
    }

    const actorsGender = [{ "key": "Male", "val": "male" }, { "key": "Female", "val": "female" }];

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log("value==========================>", value, "name--------->", name, "type---------->", type)
            setData({ ...value, accessToken: accessToken })
        }
        );
        return () => subscription.unsubscribe();
    }, [watch]);


    useEffect(() => {
        console.log('editData', editData)
        if (editData) {
            setValue('name', editData.name);
            setValue('gender', editData.gender);
            // setFocus('name');
            // setFocus('gender');
            setImage([editData.image]);
            setshrink(true);
        }
        console.log('image', image)
    }, [editData]);


    useEffect(() => {
        console.log('fromSubmissionState', fromSubmissionState);
        if (fromSubmissionState)
            navigate("/dashboard", { replace: true });
        return () => store.dispatch(resetArtistFromSubmissionState({fromSubmissionState:false}));

    }, [fromSubmissionState]);


    useEffect(() => {
        if (!editData && param.id)
            dispatch(findArtist(`_id=${param.id}`));
        return () =>store.dispatch(resetEditDataArtist());

    }, [])


    return (
        <>
            <div className="main-block">

                <div className="artist_main_wrapper">

                    <BackendTopBar />

                </div>

                <div className='form_section'>
                    <form>


                        <div className='firstSection'>

                            <div className='first_block blocks'>

                                <h3> Artist Name </h3>
                                <div className=''>

                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <TextField id="outlined-basic" InputLabelProps={{ shrink: shrink}} label="Artist Name" variant="outlined" className='form_feilds' value={value?value:null} onBlur={onBlur} onChange={onChange} 
                                            
                                            error={Object.keys(errors).length > 0 && errors.name != null}
                                        helperText={errors.name && "This Field is Required"}
                                            />
                                        )}
                                    />



                                </div>
                            </div>


                            <div className='second_block blocks'>

                                <h3> Gender </h3>
                                <div>
                                    <Controller
                                        name="gender"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        // defaultValue=[]
                                        render={({ field: { onChange, onBlur, value } }) => (

                                            <FormControl sx={{ m: 1, width: 300 }}>
                                                <InputLabel shrink id="demo-multiple-chip-label">Gender</InputLabel>


                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    id="drive-type-select"
                                                    value={value ? value : null}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    input={<OutlinedInput label="Name" />}
                                                    error={Boolean(errors.gender)}
                                                // MenuProps={MenuProps}
                                                >
                                                    {actorsGender?.map((name) => (
                                                        <MenuItem
                                                            key={name.key}
                                                            value={name.val}
                                                        >
                                                            {name.key}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText error={Object.keys(errors).length > 0 && errors.gender != null && errors.gender}>{errors.gender && "This Field is Required"}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className='secondSection'>

                            <div className='first_block blocks'>
                                <h3> Image </h3>
                                <ImageUploader urls={image} setUrls={setImage} single={true}/>
                            </div>
                        </div>

                                    {loading && <LinearProgress color="success" />}

                        <div className='buttonSection'>
                            <Button variant="contained" onClick={handleSubmit(onSubmit)}>Submit</Button>
                        </div>
                    </form>


                </div>
            </div>
        </>
    )
}

export default ArtistAddEdit