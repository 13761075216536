import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const artistList = createAsyncThunk('user/list-artist', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}artist/list`, requestOptions);
  const res = await response.json();
  return res;
})

export const artistCount = createAsyncThunk('user/count-artist', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}artist/count`, requestOptions);
  const res = await response.json();
  return res;
})


export const addArtist = createAsyncThunk('user/add-artist', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}artist/add`, requestOptions);
  const res = await response.json();
  return res;
})


export const updateArtist = createAsyncThunk('user/update-artist', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}artist/update`, requestOptions);
  const res = await response.json();
  return res;
})


export const findArtist = createAsyncThunk('user/find-artist', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true }
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}artist/find?${body}`, requestOptions);
  const res = await response.json();
  return res;
})


export const artistNameAutocomplete = createAsyncThunk('user/autocomplete-artist', async (body) => {
  // console.log('body====>', body)
  const token = body.accessToken;
  delete body.accessToken;
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": true },
    // body: JSON.stringify(body)
  };
  token && (requestOptions.headers = { ...requestOptions.headers, "access_token": token })
  console.log('requestOptions :>> ', requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}artist/auto-complete?name=${body}`, requestOptions);
  const res = await response.json();
  return res;
})



const artistSlice = createSlice({
  name: 'artistSlice',
  initialState: {
    loading: false,
    artistData: [],
    artistCount: 0,
    artistEditData: null,
    artistAutocomplete: [],
    fromSubmissionState:false,
    snackFlag: false,
    snackMessage: null,
    snackType: null,

  },
  reducers: {
    resetArtistFromSubmissionState(state, action) {
      if (action.payload !== undefined && action.payload.fromSubmissionState === false) {
        state.fromSubmissionState = false;
      }
    },

    resetArtistSnackbar(state, action) {
      state.snackFlag = false;
      state.snackMessage = null;
      state.snackType = null
    },
    resetEditDataArtist(state, action) {
      state.artistEditData= null
    }



  },
  extraReducers: {
    [artistList.pending]: (state, action) => {
      state.loading = true;
    },
    [artistList.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status == 'success') {
        state.artistData = action.payload.response.artistData;
      }
    },
    [artistList.rejected]: (state, action) => {
      state.loading = false;
    },


    [artistCount.pending]: (state, action) => {
      state.loading = true;
    },
    [artistCount.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status == 'success') {
        state.artistCount = action.payload.response.artistData;
      }
    },
    [artistCount.rejected]: (state, action) => {
      state.loading = false;
    },


    [addArtist.pending]: (state, action) => {
      state.loading = true;
    },
    [addArtist.fulfilled]: (state, action) => {
      state.snackFlag = true;
      state.loading = false;
      if (action.payload.status == 'success') {
        state.artistData = action.payload.response.artistData;
        state.fromSubmissionState = true;
        state.snackMessage = action.payload.response.message
        state.snackType = 'success';
      }
      if (action.payload.status == 'error') {
        state.snackMessage = action.payload.response.message;
        state.snackType = 'error';
      }
    },
    [addArtist.rejected]: (state, action) => {
      state.loading = false;
    },


    [updateArtist.pending]: (state, action) => {
      state.loading = true;
    },
    [updateArtist.fulfilled]: (state, action) => {
      state.snackFlag = true;
      state.loading = false;
      if (action.payload.status == 'success') {
        state.artistData = action.payload.response.artistData;
        state.fromSubmissionState = true;
        state.snackMessage = action.payload.response.message
        state.snackType = 'success';
      }
      if (action.payload.status == 'error') {
        state.snackMessage = action.payload.response.message;
        state.snackType = 'error';
      }
    },
    [updateArtist.rejected]: (state, action) => {
      state.loading = false;
    },


    [findArtist.pending]: (state, action) => {
      state.loading = true;
    },
    [findArtist.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status == 'success') {
        state.artistEditData = action.payload.response.artistData;
      }
    },
    [findArtist.rejected]: (state, action) => {
      state.loading = false;
    },


    [artistNameAutocomplete.pending]: (state, action) => {
      state.loading = true;
    },
    [artistNameAutocomplete.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status == 'success') {
        state.artistAutocomplete = action.payload.response.artistData;
      }
    },
    [artistNameAutocomplete.rejected]: (state, action) => {
      state.loading = false;
    },
  }
})



export const {resetArtistFromSubmissionState, resetArtistSnackbar, resetEditDataArtist } = artistSlice.actions;
export default artistSlice.reducer;