
import { useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './projectdetails.css'
import { fetchProjectDetails, recommenedProjects } from './ProjectReducer';
import YouTubeIcon from '@mui/icons-material/YouTube';
import hoichoi from '../../../img/hoichoi.webp'
import zee from '../../../img/zee.png'
import { findProject } from '../../Backend/Projects/ProjectReducer';
import ImageSlider from '../../../CommonComponent /Slider/ImageSlider';
import { CircularProgress } from '@mui/material';


const ProjectDetails = () => {
    
    const param = useParams();
    const dispatch = useDispatch();
   

    const projectDetailsData = useSelector((state)=> state?.productSlice?.projectData? [state.productSlice.projectData] : [])
    const recommedProject = useSelector((state)=> state?.ProductReducer?.recommedProject? state.ProductReducer.recommedProject : [])
    const loading = useSelector((state) => state?.ProductReducer?.loading ? state.ProductReducer.loading : false)
    

    useEffect(() => {
        window.scrollTo(0, 0);
        if(Object.keys(param).length>0)
            dispatch(findProject(`_id=${param.id}`))
            dispatch(recommenedProjects({_id:param.id}))
    }, [JSON.stringify(param)])
    


    return (
        <>
            <div className='projectdetails_MainWrapper'>
                <div className='main_Wrapper'>

                    <div className='top_Section'>

                        <div className='leftSection'>
                            {
                                (projectDetailsData[0]?.poster_verticle)?
                                <img src={projectDetailsData[0]?.poster_verticle} alt="Film Poster" className="film_poster" />:
                                 <CircularProgress />
                            
                            }
                        </div>

                        <div className='rightSection'>
                            <h1 className="project_heading"> { projectDetailsData[0]?.project_name}  <span className="category">({ projectDetailsData[0]?.sub_category})</span></h1>
                           
                            <div className='description'>
                                <div dangerouslySetInnerHTML={{__html: projectDetailsData[0]?.description}} className="content"/>
                            </div>

                            <div className='teamsx'>
                                <ul className="units">
                                    <li> 
                                        <h3> Director</h3>
                                        <h4> {projectDetailsData[0]?.directors_name}</h4>
                                    </li>

                                    <li> 
                                        <h3> Producer</h3>
                                        <h4> {projectDetailsData[0]?.producers_name}</h4>
                                    </li>

                                    <li> 
                                        <h3> Script Writter</h3>
                                        <h4> {projectDetailsData[0]?.writter_name}</h4>
                                    </li>

                                    <li> 
                                        <h3> Music Director</h3>
                                        <h4> {projectDetailsData[0]?.music_director_name}</h4>
                                    </li>

                                </ul>
                            </div>
                            <div className='links'>

                              <p className="link"> WATCH TRAILER ON &nbsp; <YouTubeIcon className="yt" onClick={()=> window.open(projectDetailsData[0]?.trailer_link, '_blank')}/> </p>
                                <p className="link"> WATCH  ON &nbsp;
                                  
                                    <img src={hoichoi} alt="HoiChoi" className="icon" onClick={()=> window.open(projectDetailsData[0]?.hoichoi_link, '_blank')}/>
                                    <img src={zee} alt="Zee5" className="icon" onClick={()=> window.open(projectDetailsData[0]?.zee5_link, '_blank')} />
                                </p>
                         
                              
                                
                            </div>
                        </div>

                    </div>
                </div>

                <div className='second_section'>
                        <div className="content_part">
                            <h2> CASTING</h2>
                            <p> Discover Our Talented Cast Bringing Stories to Life On Screen </p>
                        
                            <div className='img-block'>

                            {projectDetailsData[0]?.press_release?.length>0 ? projectDetailsData[0]?.press_release?.map((x) => {
                                return(
                                    (<div className='cards'>
                                        <img src={x} alt="Project Poster" className='card-img' />
                                               
                                    </div>))
                            }): <p className="errormsg"> No Data Found!</p>}
 
                            </div>
                        </div>
                </div>

                <div className='third_section'>
                        <div className="content_part">
                            <h2> PRESS RELEASE</h2>
                            <p> Read the Latest Updates and News from Our Dynamic World </p>
                        
                            <div className='img-block'>

                            {projectDetailsData[0]?.press_release?.length>0 ? projectDetailsData[0]?.press_release?.map((x) => {
                                return(
                                    (<div className='cards'>
                                        <img src={x} alt="Project Poster" className='card-img' />
                                               
                                    </div>))
                            }): <p className="errormsg"> No Data Found!</p>}
 
                         </div>
                        </div>
                </div>

                <div className='fourth_section'>
                        <div className="content_part">
                            <h2> ASSOCIATED PARTNERS</h2>
                            <p> Explore Our Trusted Partnerships That Elevate Our Creative Endeavors </p>
                        
                        <div className='img-block'>
                            {projectDetailsData[0]?.associate_partners?.length>0 ? projectDetailsData[0]?.associate_partners?.map((x) => {
                                return(
                                    (<div className='cards'>
                                        <img src={x} alt="Project Poster" className='card-img' />
                                               
                                    </div>))
                            }): <p className="errormsg"> No Data Found!</p>}
 
                        </div>
                        </div>
                </div>


                <div className="slider_section">
                    <div className="content">
                        
                        <div className='heading'>
                            <h2> OTHER PROJECTS</h2>
                            <p> Explore More of Our Diverse Project Portfolio Here </p>
                        </div>

                        <div className='slider'>
                          <ImageSlider slidedata={recommedProject} />
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}

export default ProjectDetails;