
import * as React from 'react';
import { Suspense } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
// import { accesscontroll } from '../helper/helperFunctions';
import Store from './store';


import About from './Pages/FrontEnd/About/About';
import Contact from './Pages/FrontEnd/Contact/Contact';
import Home from './Pages/FrontEnd/Home/Home';
import Projects from './Pages/FrontEnd/Projects/Projects';
import { useSelector } from 'react-redux';
import Login from './Pages/FrontEnd/Login/Login';
import Layout from './Layout/Layout';
import ProjectDetails from './Pages/FrontEnd/ProjectDetails/ProjectDetails';
import ProjectAddEdit from './Pages/Backend/Projects/ProjectsAddEdit';
import { setLogedinUserInfo } from './Pages/FrontEnd/Login/LoginReducer';
import store from './store';
import NoPage from './NoPage';
import TeamAddEdit from './Pages/Backend/TeamManagement/TeamAddEdit';
import ArtistAddEdit from './Pages/Backend/ArtistDirectory /ArtistAddEdit';




// ---- Back End Lazy Loading -----

const Dashboard = React.lazy(() => import('./Pages/Backend/Dashboard/Dashboard.js'))





const getCookiesSetStore = () => {
  
  const cookies = new Cookies();
  // console.log('object :>> in index');
  const getcookies = cookies.getAll();
  // console.log("getcookies from index==>", getcookies);
  if (getcookies !== null && getcookies !== undefined && Object.keys(getcookies).length > 0) {
    // console.log('getcookies==>', getcookies)
    store.dispatch(setLogedinUserInfo(getcookies));
  }
}
////////////////////////////////// Call the getCookiesSetStore function /////////////////////////////////////
getCookiesSetStore();

// console.warn("<<<< cookie details from routes >>>>", cookies.cookies)
// ---- Front End Lazy Loading -----
const useAuth = () => {


  if (Store.getState().loginSlice.isLoggedIn || (Store.getState().loginSlice?.userInfo && Object.keys(Store.getState().loginSlice.userInfo).length > 0)) {
    return true;
  }
}

const PrivateRoute = ({ children }) => {
  // console.log("authed", useAuth());
  const auth = useAuth();

  return (
    auth === true ? children : <Navigate to="/admin-login" replace={true} />
  )
}

const LoginRoute = ({ children, redirectPath }) => {
  const auth = useAuth();
  return (auth !== true) ? children : <Navigate to={redirectPath} replace={true} />
}



const Routess = () => {

  const dataAll = useSelector(state => state.ResolveReducer?.httprsponsedata ? state.ResolveReducer?.httprsponsedata : [])

  // console.log(" process.env", process.env);


  React.useEffect(() => {
    // console.log("data===============>", dataAll);
      
    if (dataAll.length > 5) {
    //   store.dispatch(setHttpResponse(dataAll))
    //   store.dispatch(clearSethttprsponsedata())
    }

  }, [JSON.stringify(dataAll)])


  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);





  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>

       {/* ------- Frontend Pages ------- */}
        <Route path="/admin-login" element={<LoginRoute redirectPath={'/dashboard'}><Suspense fallback={<></>}><Login /></Suspense></LoginRoute>} />{" "}
        <Route path="/" element={<Home />} />{" "}
        <Route path="/about" element={<About />} />{" "}
        <Route path="/contact" element={<Contact />} />{" "}
        <Route path="/projects" element={<Projects />} />{" "}
        <Route path="/projects-details/:id" element={<ProjectDetails />} />{" "}
       {/*-----------------------------  */}


       {/* ---------- Backend Pages --------- */}
          <Route path="/dashboard" element={<PrivateRoute><Suspense fallback={<></>}><Dashboard /></Suspense></PrivateRoute>} />{" "}
         
          <Route path="/add-project" element={<PrivateRoute><Suspense fallback={<></>}><ProjectAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/edit-project/:id" element={<PrivateRoute><Suspense fallback={<></>}><ProjectAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/add-artist" element={<PrivateRoute><Suspense fallback={<></>}><ArtistAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/edit-artist/:id" element={<PrivateRoute><Suspense fallback={<></>}><ArtistAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/add-member" element={<PrivateRoute><Suspense fallback={<></>}><TeamAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/edit-member/:id" element={<PrivateRoute><Suspense fallback={<></>}><TeamAddEdit /></Suspense></PrivateRoute>} />
          
       {/* ---------------------------------- */}
          <Route path='*' element={<NoPage/>} />

        </Route>
      </Routes>
    </BrowserRouter>
  );

}

export default Routess;