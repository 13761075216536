import Slider from "react-slick";
// import store from "../../store";
import React, { useEffect, useRef, useState } from "react";
import './projectslider.css'
import { Button } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProjectSlider = (props) => {

    const navigate = useNavigate()


    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,  
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        cssEase: "linear",
        centerPadding: "60px",
        responsive: [
            {
              breakpoint: 1142,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 875,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    return (
        <>
            <div className="slider">

                {/* ------- image slider --------- */}
                <Slider {...settings}>

                   
                   
                    {props.slidedata.map((x) => {
                        return (
                            <div key={x._id} onClick={()=> navigate('/projects-details/'+x._id)} > 
                                <div className="wrapper">
                                    <div className="subwrap">
                                       <div className="imgg" >
                                            <img src={x.poster_verticle} className="img" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
  
                })}
                  
                </Slider >

                {/* ----------------- */}



            </div >
        </>
    )
}

export default ProjectSlider;
