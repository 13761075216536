import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageSlider from "../../../CommonComponent /Slider/ImageSlider";
import { projectLising } from "../../Backend/Projects/ProjectReducer";
import "./home.css"
import TopBanner from "./TopBanner";
 

const Home = () => {
    
    const dispatch = useDispatch();
    const projectListingData = useSelector((state)=> state.productSlice?.projectListingData? state.productSlice?.projectListingData : [])


    useEffect(() => {
        dispatch(projectLising({}))
    },[])

    return (
        <>
            <div className="main_container">

                <div className="tpBanner-section">
                    <TopBanner slidedata={projectListingData}/>
                </div>

                <div className="second_section">
                    <div className="recent_projectWrapper">
                        <div className="heading">
                            <h1> RECENT PROJECTS</h1>
                            <p>  Discover Our Showcase of Recent Projects. </p>
                        </div>
                        <div className="slider_section">
                            <ImageSlider slidedata={projectListingData} />
                        </div>
                    </div>
                </div>
            
            </div>
        </>
    );

}

export default Home;