
import './frontendfooter.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useNavigate } from 'react-router-dom';

const FrontendFooter = () => {

    const navigate = useNavigate();

    return (
        <>
            
            <div className='socialMedia_aaction'>
                <div className='heading'>
                    <h1> ON SOCIAL NETWORKS </h1>
                </div>
                <div className='social_icons'>
                    <FacebookIcon className='icons'/>
                    <TwitterIcon className='icons'/>
                    <YouTubeIcon className='icons'/> 
                </div>
            </div>


            <div className='frontendfooter_wrapper'>
                <div className='footer'>
                    
                    <div className='block first_block'>
                        <h2> DREAMLINER ENTERTAINMENT</h2>
                        <p> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>

                        <p> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                    </div>

                    <div className='block second_block'>
                        <h2> QUICK LINKS </h2>
                        <ul>
                            <li onClick={()=> navigate('/')}> HOME</li>
                            <li onClick={()=> navigate('/projects')}> PROJECTS</li>
                            <li onClick={()=> navigate('/about')}> ABOUT US</li>
                            <li onClick={() => navigate('/contact')}> CONTACT US</li>
                        </ul>
                    </div>

                    <div className='block third_block'>
                        <h2> CONTACT INFORMATION</h2>
                        
                    </div>

                </div>
            </div>

            <div className='after_footersection'>
                    <p> Copyright &copy; DREAMLINER ENTERTAINMENT | Design & Developed By InfoTechEdge</p>
            </div>

        </>
    )
}

export default FrontendFooter;