
import './contact.css'
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import BusinessIcon from '@mui/icons-material/Business';


function Contact() {

    return (
        <>
            
            <div className="maincontainer_contact">

                <div className="banner_section">
                    <h2> Heading Here</h2>
                    <p> 
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                   </p>
                </div>

                <div className='secondSection'>
                    <div className='cards'>
                        <p className=''> <AttachEmailIcon  className='icons'/> </p>
                         connect@dreamlinerentertainment.com
                    </div>

                    <div className='cards'>
                        <p className=''> <BusinessIcon className='icons'/> </p>
                         connect@dreamlinerentertainment.com
                    </div>

                </div>


            </div>
        </>
    );
    
}

export default Contact;