
import { useState, useEffect } from "react"
import { Autocomplete, Box, Button, Chip, FormControl, FormHelperText, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import BackendTopBar from '../../../Layout/BackendHeader/BackendTopBar';
import './projectaddedit.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import ImageUploader from '../../../CommonComponent /ImageUpload/ImageUploader';
import { addUpdateProject, findProject, resetProjectEditData, resetProjectSubmissionState } from "./ProjectReducer";
import { artistNameAutocomplete } from "../ArtistDirectory /ArtistReducer";
import store from "../../../store";
import { useNavigate, useParams } from "react-router-dom";


const ProjectAddEdit = () => {
    const loader = useSelector((state) => (state?.productSlice?.loading) ? state.productSlice.loading : false);
    const param = useParams();
    const [shrink, setshrink] = useState(false);
    const fromSubmissionState = useSelector((state) => state?.productSlice?.fromSubmissionState);
    const editData = useSelector((state) => (state?.productSlice?.projectData) ? state.productSlice?.projectData : null);
    const accessToken = useSelector((state) => state?.loginSlice?.access_token ? state.loginSlice.access_token : null);
    const { control, watch, setValue, getValues, handleSubmit, formState: { errors }, clearErrors } = useForm();
    const [actorsData, setActorsData] = useState([]);
    const [associate_partners, setAssociatePartners] = useState([]);
    const [poster_verticle, setPosterVerticle] = useState([]);
    const [poster_horizontal, setPosterHorizontal] = useState([]);
    const [press_release, setPressRelease] = useState([]);
    const [data, setData] = useState({})
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const category = [
        { "key": "feature_films", "val": "Feature films" },
        { "key": "short_films", "val": "Short Films" },
    ];

    const subCategory = [
        { "key": "horror", "val": "Horror" },
        { "key": "action", "val": "Action" },
        { "key": "drama", "val": "Drama" },
        { "key": "comedy", "val": "Comedy" },
        { "key": "romance", "val": "Romance" },
        { "key": "mystery", "val": "Mystery" },
        { "key": "thriller", "val": "Thriller" },

    ];

    // console.log("poster_horizontal =======>>>>>>", poster_horizontal)


    //autocomplete -------

    // const [value, setValue] = useState([]);
    const [query, setQuery] = useState();

    const artistList = useSelector((state) => state.artistSlice?.artistAutocomplete);
    const onChangeAutocomplete = (event, newValue) => {
        console.log(newValue, 'event', event);
        setValue('actors_name', newValue);
        console.log('artistList.filter(artist => artist.name === newValue)======', artistList.filter(artist => artist.name === newValue))
        newValue.map((val) =>
            setActorsData([...actorsData, ...artistList.filter(artist => artist.name === val
            )])
        );
    }

    useEffect(() => {
        console.log('actorsData', actorsData)
    }, [actorsData]);



    useEffect(() => {
        if (query) {
            const getData = setTimeout(() => {
                dispatch(artistNameAutocomplete(query))

            }, 1000)

            return () => clearTimeout(getData)
        }
    }, [query]);

    //----------------------------------------------------------------

    const onChange = (event, flag) => {
        console.log('event.target', event, flag)
        setValue(flag, event.target.value)
        clearErrors(flag)
    }

    const onChangeDate = (event) => {
        // console.log(' onChangeDate event.target', event)
        let date = new Date(event.$d)
        var timestamp = date.getTime();
        // date = Math.floor(timestamp / 1000)
        // date = (dateone / 1000)
        // console.log(' onChangeDate event.target', timestamp)

        setValue('release_date', timestamp)
    }

    const onSubmit = () => {
        data.actors = actorsData;
        data.poster_verticle = poster_verticle[0];
        data.poster_horizontal = poster_horizontal[0];
        console.log('data :>> ', data);
        // if (data.accessToken)

        if (param.id) {
            data._id = param.id;
            dispatch(addUpdateProject(data));
        } else
            dispatch(addUpdateProject(data));
    }


    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log('value', value)
            setData({ ...value, 'status': true, accessToken: accessToken })
        }
        );
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (editData) {
            setshrink(true);
            setActorsData(editData.actors);
            let actorsName = editData.actors.length > 0 ? editData.actors.map(actor => actor.name) : [];
            setValue('project_name', editData.project_name);
            setValue('trailer_link', editData.trailer_link);
            setValue('hoichoi_link', editData.hoichoi_link);
            setValue('zee5_link', editData.zee5_link);
            setValue('category', editData.category);
            setValue('sub_category', editData.sub_category);
            setValue('release_date', editData.release_date);
            setValue('directors_name', editData.directors_name);
            setValue('producers_name', editData.producers_name);
            setValue('writter_name', editData.writter_name);
            setValue('music_director_name', editData.music_director_name);
            setValue('actors_name', actorsName);
            setPosterHorizontal([editData.poster_horizontal]);
            setPosterVerticle([editData.poster_verticle]);
            setValue('description', editData.description);
            setAssociatePartners([...associate_partners, ...editData.associate_partners]);
            setPressRelease([...press_release, ...editData.press_release]);
        }
    }, [editData]);



    // if poster_horizontal upload then setfor final upload during form submit //
    // useEffect(() => {

    //     if (poster_horizontal.length > 0) {
    //         setValue('poster_horizontal', poster_horizontal[0])
    //     }

    // }, [poster_horizontal])
    //  ---------------------------------------------------------  //


    //  if poster_verticle upload then setfor final upload during form submit  //
    // useEffect(() => {

    //     if (poster_verticle.length > 0) {
    //         setValue('poster_verticle', poster_verticle[0])
    //     }
    // }, [poster_verticle])
    // ---------------------------------------------------------  //



    // if associate_partners upload then setfor final upload during form submit  //
    useEffect(() => {

        if (associate_partners.length > 0) {
            setValue('associate_partners', associate_partners)
        }

    }, [associate_partners])
    //  ---------------------------------------------------------//



    //  if press_release upload then setfor final upload during form submit  //
    useEffect(() => {

        if (press_release.length > 0) {
            setValue('press_release', press_release)
        }

    }, [press_release])
    // ---------------------------------------------------------  //


    useEffect(() => {
        console.log('fromSubmissionState', fromSubmissionState);
        if (fromSubmissionState)
            navigate("/dashboard", { replace: true });
        return () => store.dispatch(resetProjectSubmissionState());

    }, [fromSubmissionState]);


    useEffect(() => {
        if (!editData && param.id)
            dispatch(findProject(`_id=${param.id}`));
        return () => store.dispatch(resetProjectEditData());

        //   return () => {

        //   }
    }, [])




    return (
        <>

            <div className='main-block'>
                <div className='projects_mainwrapper'>
                    <BackendTopBar />
                </div>

                <div className='form_section'>
                    <form>


                        <div className='firstSection'>

                            <div className='first_block blocks'>

                                <h3> Project Introduction </h3>
                                <div className=''>

                                    <Controller
                                        name="project_name"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        render={() => (
                                            <TextField id="outlined-basic" InputLabelProps={{ shrink: shrink }} label="Project Name" variant="outlined" className='form_feilds' value={getValues('project_name')} onChange={(event) => { onChange(event, 'project_name') }}
                                                error={Object.keys(errors).length > 0 && errors.project_name != null}
                                                helperText={errors.project_name && "This Field is Required"}

                                            />
                                        )}
                                    />
                                    <Controller
                                        name="trailer_link"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        render={() => (
                                            <TextField id="outlined-basic" InputLabelProps={{ shrink: shrink }} label="Trailer Link" variant="outlined" className='form_feilds' value={getValues('trailer_link')} onChange={(event) => { onChange(event, 'trailer_link') }}
                                                error={Object.keys(errors).length > 0 && errors.trailer_link != null}
                                                helperText={errors.trailer_link && "This Field is Required"}
                                            />
                                        )} />
                                    <Controller
                                        name="hoichoi_link"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        render={() => (
                                            <TextField id="outlined-basic" InputLabelProps={{ shrink: shrink }} label="Hoichoi Link" variant="outlined" className='form_feilds' value={getValues('hoichoi_link')} onChange={(event) => { onChange(event, 'hoichoi_link') }}
                                                error={Object.keys(errors).length > 0 && errors.hoichoi_link != null}
                                                helperText={errors.hoichoi_link && "This Field is Required"}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="zee5_link"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        render={() => (
                                            <TextField id="outlined-basic" InputLabelProps={{ shrink: shrink }} label="ZEE5 Link" variant="outlined" className='form_feilds' value={getValues('zee5_link')} onChange={(event) => { onChange(event, 'zee5_link') }}
                                                error={Object.keys(errors).length > 0 && errors.zee5_link != null}
                                                helperText={errors.zee5_link && "This Field is Required"}
                                            />
                                        )}
                                    />


                                    <h3> Category </h3>
                                    <div>

                                        <Controller
                                            name="category"
                                            control={control}
                                            rules={{ required: "This field is required" }}
                                            // defaultValue=[]
                                            render={({ field: { onChange, onBlur, value } }) => (

                                                <FormControl sx={{ m: 1, width: 300 }}>
                                                    <InputLabel shrink id="demo-multiple-chip-label">Category</InputLabel>


                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        id="drive-type-select"
                                                        value={value ? value : null}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        error={Object.keys(errors).length > 0 && errors.category != null}
                                                    // helperText={errors.category && "This Field is Required"}
                                                    >
                                                        {category?.map((name) => (
                                                            <MenuItem
                                                                key={name.key}
                                                                value={name.key}
                                                            >
                                                                {name.val}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText error={Object.keys(errors).length > 0 && errors.category != null && errors.category}>{errors.category && "This Field is Required"}</FormHelperText>
                                                </FormControl>
                                            )}
                                        />
                                    </div>
                                    <h3> Sub Category </h3>
                                    <div>
                                        <Controller
                                            name="sub_category"
                                            control={control}
                                            rules={{ required: "This field is required" }}
                                            // defaultValue=[]
                                            render={({ field: { onChange, onBlur, value } }) => (

                                                <FormControl sx={{ m: 1, width: 300 }}>
                                                    <InputLabel shrink id="demo-multiple-chip-label">Sub Category</InputLabel>


                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        id="drive-type-select"
                                                        value={value ? value : []}
                                                        onBlur={onBlur}
                                                        multiple={true}
                                                        onChange={onChange}
                                                        error={Object.keys(errors).length > 0 && errors.sub_category != null}

                                                    >
                                                        {subCategory?.map((name) => (
                                                            <MenuItem
                                                                key={name.key}
                                                                value={name.key}
                                                            >
                                                                {name.val}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText error={Object.keys(errors).length > 0 && errors.sub_category != null && errors.sub_category}>{errors.sub_category && "This Field is Required"}</FormHelperText>
                                                </FormControl>
                                            )}
                                        />
                                    </div>



                                </div>


                            </div>


                            <div className='second_block blocks'>

                                <h3> Associate Core Team </h3>
                                <div className=''>

                                    <Controller
                                        name="directors_name"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        render={() => (
                                            <TextField id="outlined-basic" InputLabelProps={{ shrink: shrink }} label="Director Name" variant="outlined" className='form_feilds' value={getValues('directors_name')} onChange={(event) => { onChange(event, 'directors_name') }}
                                                error={Object.keys(errors).length > 0 && errors.directors_name != null}
                                                helperText={errors.directors_name && "This Field is Required"}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="producers_name"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        render={() => (
                                            <TextField id="outlined-basic" InputLabelProps={{ shrink: shrink }} label="Producer Name" variant="outlined" className='form_feilds' value={getValues('producers_name')} onChange={(event) => { onChange(event, 'producers_name') }}
                                                error={Object.keys(errors).length > 0 && errors.producers_name != null}
                                                helperText={errors.producers_name && "This Field is Required"}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="writter_name"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        render={() => (
                                            <TextField id="outlined-basic" InputLabelProps={{ shrink: shrink }} label="Writter Name" variant="outlined" className='form_feilds' value={getValues('writter_name')} onChange={(event) => { onChange(event, 'writter_name') }}
                                                error={Object.keys(errors).length > 0 && errors.writter_name != null}
                                                helperText={errors.writter_name && "This Field is Required"}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="music_director_name"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        render={() => (
                                            <TextField id="outlined-basic" InputLabelProps={{ shrink: shrink }} label="Music Director Name" variant="outlined" className='form_feilds' value={getValues('music_director_name')} onChange={(event) => { onChange(event, 'music_director_name') }}
                                                error={Object.keys(errors).length > 0 && errors.music_director_name != null}
                                                helperText={errors.music_director_name && "This Field is Required"}
                                            />
                                        )} />


                                </div>

                                <h3> Casting </h3>
                                <div>
                                    <Controller
                                        name="actors_name"
                                        control={control}
                                        rules={{ required: "This field is required" }}
                                        // defaultValue=[]
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Autocomplete
                                                value={value ? value : []}
                                                onChange={(event, newValue) => {
                                                    onChangeAutocomplete(event, newValue);
                                                }}
                                                multiple
                                                id="tags-filled"
                                                options={artistList.map((option) => option.name)}
                                                freeSolo
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="filled"
                                                        label="Actors"
                                                        placeholder="Search"
                                                        onChange={(e) => {
                                                            setQuery(e.target.value);

                                                            // onChange(e);
                                                        }}
                                                        error={Object.keys(errors).length > 0 && errors.actors_name != null}
                                                        helperText={errors.actors_name && "This Field is Required"}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </div>

                                <h3> Release Date </h3>
                                <div>
                                    <Controller
                                        name="release_date"
                                        control={control}
                                        rules={{ required: "This field is required" }}

                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Release Date*"
                                                    value={value ? dayjs(value) : null}
                                                    // value={moment(getValues('register_date'))}
                                                    // defaultValue={dayjs(productData?.release_date)}
                                                    onChange={onChangeDate}
                                                    slotProps={{
                                                        textField: {
                                                            required: true,
                                                    error:(Object.keys(errors).length > 0 && errors.release_date != null),
                                                    helperText:(errors.release_date && "This Field is Required")
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>

                                        )}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className='secondSection'>

                            <div className='first_block blocks'>
                                <h3> Project Poster Horizontal</h3>
                                <ImageUploader urls={poster_horizontal} setUrls={setPosterHorizontal} single={true} imgSize="120px-X-300px"/>
                            </div>

                            <div className='first_block blocks'>
                                <h3> Project Poster Verticle</h3>
                                <ImageUploader urls={poster_verticle} setUrls={setPosterVerticle} single={true} />
                            </div>

                        </div>



                        <div className='secondSection'>


                            <div className='first_block blocks'>
                                <h3> Project Description </h3>
                                <div className=''>

                                    <Controller
                                        name="description"
                                        control={control}
                                        // rules={{ required: "This field is required" }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <SunEditor
                                                setOptions={{
                                                    buttonList: [
                                                        ['undo', 'redo'],
                                                        ['bold', 'underline', 'italic', 'list'],
                                                        // ['link', 'image'],
                                                    ]
                                                }}
                                                width="100%"
                                                height="200"
                                                allowfullscreen="disabled"
                                                loading="lazy"
                                                onChange={onChange}
                                                setContents={value ? value : null}
                                            />
                                        )}
                                    />

                                </div>
                            </div>
                        </div>

                        <div className='secondSection'>

                            <div className='first_block blocks'>
                                <h3> Associate Partners </h3>
                                <ImageUploader urls={associate_partners} setUrls={setAssociatePartners} />
                            </div>
                        </div>



                        <div className='secondSection'>

                            <div className='first_block blocks'>
                                <h3> Press Release </h3>
                                <ImageUploader urls={press_release} setUrls={setPressRelease} />
                            </div>
                        </div>

                        {loader && <LinearProgress color="success" />}

                        <div className='buttonSection'>
                            <Button variant="contained" onClick={handleSubmit(onSubmit)}>Submit</Button>
                        </div>
                    </form>


                </div>


            </div>

        </>
    );
}

export default ProjectAddEdit;